import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type IBurgerState = {
  isOpen: boolean;
  toggleBurger: (newState?: boolean) => void;
};

export const useBurgerState = create<IBurgerState>()(
  persist(
    (set) => ({
      isOpen: true,
      toggleBurger: (newState?: boolean) => {
        if (newState === undefined) {
          set((state) => ({ isOpen: !state.isOpen }));
        } else {
          set({ isOpen: newState });
        }
      },
    }),
    {
      name: 'burger',
      getStorage: () => localStorage,
    },
  ),
);
