import dayjs from 'dayjs';
import CONSTANTS from '@mygigtechnologies/constants';
dayjs.Ls.en.weekStart = 1; //начало недели в понедельник

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DETAILS_DATE_FORMAT = 'YYYY.MM.DD HH:mm';

export const REACT_APP_SUPPORT_PHONE =
  import.meta.env.REACT_APP_SUPPORT_PHONE || '8 800 333-03-29';

export const HOSTED_ENVS = new Set(['production', 'staging', 'test']);

export const DOCUMENTS = new Map(Object.entries(CONSTANTS.DOCS));

export const GROUPS = new Map([
  ['order', 'По заказам'],
  ['template', 'По шаблонам'],
  ['spec', 'По профессии'],
  ['employer', 'По управляющим'],
  ['workplace', 'По точкам'],
  ['region', 'По городам'],
]);

export const STATUSES = new Map([
  ['active', 'Активен'],
  ['inactive', 'Завершён'],
  ['deleted', 'Отменён'],
]);

export const RANGES = [
  { label: 'Сегодня', value: [dayjs(), dayjs()] },
  {
    label: 'На прошлой неделе',
    value: [
      dayjs().startOf('week').subtract(1, 'week'),
      dayjs().endOf('week').subtract(1, 'week'),
    ],
  },
  {
    label: 'На этой неделе',
    value: [dayjs().startOf('week'), dayjs().endOf('week')],
  },
  {
    label: 'В этом месяце',
    value: [dayjs().startOf('month'), dayjs().endOf('month')],
  },
];

export const COLORS = new Set([
  '#14988B',
  '#27AE60',
  '#2F80ED',
  '#3C1A3C',
  '#54C5BA',
  '#670505',
  '#6F606F',
  '#730775',
  '#842A86',
  '#A9C554',
  '#EA6767',
  '#F00',
  '#F16393',
  '#F50202',
  '#F57C1F',
  '#F7CE17',
]);

export const SPEC_STATUSES = new Map([
  ['new', 'Новая'],
  ['doccheck', 'Требуются документы'],
  ['booked', 'Забронирована'],
  ['going', 'Исполнитель подтвердил бронирование'], // TODO: check on daily (в пути)
  ['delayed', 'Задерживается'],
  ['waiting', 'Ожидается'],
  ['checkingin', 'Подтвердите начало'],
  ['inprogress', 'Выполняется'],
  ['checkingout', 'Подтвердите завершение'],
  ['completed', 'Завершена'],
  ['confirmed', 'Подтверждена'],
  ['cancelled', 'Отменена'],
  ['toolate', 'Отменена из-за опоздания'],
  ['expired', 'Просрочена'],
  ['failed', 'Не выполнена'],
  ['repeat', 'Повторяется'],
  ['extended', 'Продлена'],
]);

export default {};
