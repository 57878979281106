import { useEffect, useMemo } from 'react';

import { useBurgerState } from './useBurgerState';

export default function useBurger() {
  const { isOpen, toggleBurger } = useBurgerState();

  const burgerWidth =
    isOpen ? 'var(--burger-width-open)' : 'var(--burger-width-close)';

  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      if (event.target !== document.body) return;
      if (event.keyCode === 219) {
        toggleBurger();
      }
    };
    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [toggleBurger]);

  return useMemo(() => {
    return {
      isOpen,
      toggleBurger,
      burgerWidth,
    };
  }, [isOpen, toggleBurger, burgerWidth]);
}
