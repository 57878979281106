import Cookies from 'js-cookie';

import { ROUTES } from '@/constants/router.constants';

import { logout } from './config'; // eslint-disable-line import/no-cycle

const { REACT_APP_DOMAIN } = import.meta.env;

export const cookieDomain = () =>
  document.location.hostname.includes('localhost') ?
    'localhost'
  : `.${REACT_APP_DOMAIN}`;

export function setCookie(key, value, expires = 7) {
  Cookies.set(key, value, {
    expires,
    domain: cookieDomain(),
  });
}

export async function onLogin(access) {
  if (!access) return;
  const { role } = JSON.parse(atob(access.split('.')[1]));

  localStorage.setItem('access', access);
  setCookie('role', role);

  window.location.href = `/${ROUTES.ORDERS.INDEX}`;
}

export function onLogout() {
  logout();
  localStorage.removeItem('access');
  ['role', 'version', 'is_closed'].forEach((key) => {
    Cookies.remove(key);
    Cookies.remove(key, {
      domain: cookieDomain(),
    });

    // иначе не тесте подхватывается токен с прода
    Cookies.remove(key, { domain: '.mygig.ru' });
  });

  window.location.assign(`${ROUTES.INDEX}`);
}

export function onVersionSwitch() {
  setCookie('hrd', 'mobile');

  window.location.assign(`https://${REACT_APP_DOMAIN}`);
}

export default {};
