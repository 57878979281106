import { useMemo } from 'react';
import { Image, Skeleton } from 'antd';

import { useProfile } from '@src/entities/user/queries';
import { useClients } from '@src/entities/supervisor/queries';
import type { User, UserRole } from '@src/entities/user';
import { getFullName, getRole } from '@src/entities/user/lib/helpers';

import { Breadcrumbs } from '../../breadcrumbs';

import css from './styles.module.scss';

const BASE_URL = import.meta.env.REACT_APP_API_URL;

function Header() {
  const { data: user } = useProfile();
  const { data: clients, isFetching: clientsFetching } = useClients();

  const fullName = useMemo(() => getFullName(user as User) || '', [user]);

  const role = useMemo(
    () => getRole(user?.role as UserRole) || '',
    [user?.role],
  );

  return (
    <div className={css.container}>
      <div className={css.breadcrumbs}>
        <Breadcrumbs />
      </div>
      <div className={css.meta}>
        <div className={css.account}>
          <span className={css.name}>{fullName}</span>
          <span className={css.role}>{role}</span>
        </div>
        {!clientsFetching ?
          <div className={css.avatars}>
            {clients && clients?.length > 5 ? null : (
              clients?.map(({ id, title }) => (
                <div key={id} className={css.avatar}>
                  <Image
                    src={`${BASE_URL}/clients/${id}/logo`}
                    alt={title}
                    width={24}
                    height={24}
                    preview={false}
                    placeholder={
                      <Skeleton.Avatar active={true} size={24} shape="circle" />
                    }
                  />
                </div>
              ))
            )}
          </div>
        : <Skeleton.Avatar active={true} size={24} shape="circle" />}
      </div>
    </div>
  );
}

export default Header;
