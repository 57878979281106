import { RouterProvider } from 'react-router-dom';

import { useRegistriesSocket } from '@src/pages';

import { router } from './router';

export default function Router() {
  useRegistriesSocket();
  return <RouterProvider router={router} />;
}
